@file:UseSerializers(UuidSerializer::class)

package com.eidu.webevents.io.account.model

import com.benasher44.uuid.Uuid
import com.eidu.webevents.serialization.UuidSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

@Serializable
data class LoginResponse(val loginResult: LoginResult, val schoolId: Uuid? = null, val authToken: String? = null)
