package com.eidu.webevents.util

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.eidu.webevents.io.db.DataFlow

@Composable
fun <T> withData(retrieveData: suspend () -> T, block: @Composable (T?) -> Unit) {
    var data: T? by remember { mutableStateOf(null) }
    LaunchedEffect(key1 = null) { data = retrieveData() }
    block(data)
}

@Composable
fun <JsType, KotlinType, ErrorType> withData(
    dataFlow: DataFlow<JsType, KotlinType, ErrorType>,
    block: @Composable (Outcome<ErrorType, KotlinType>?) -> Unit
) {
    var subscription: DataFlow.Subscription<Outcome<ErrorType, KotlinType>>? by remember { mutableStateOf(null) }
    DisposableEffect(key1 = null) {
        subscription = dataFlow.subscribe()
        onDispose { subscription?.unsubscribe?.invoke() }
    }
    block(subscription?.flow?.collectAsState()?.value)
}
