package com.eidu.webevents.io.encryption

import com.eidu.webevents.io.account.LoginError
import com.eidu.webevents.util.Outcome
import com.eidu.webevents.util.map
import com.eidu.webevents.util.mapError

suspend fun KeyStorage.storeEncryptionKey(client: EncryptionClient): Outcome<LoginError, EncryptionKeyResult> =
    client
        .getEncryptionKey()
        .mapError { LoginError.Client(it) }
        .map { response ->
            response.key?.let(EncryptionKeyResult::EncryptionKey)?.also { storeIfNew(schoolEncryptionKey = it.key) }
                ?: EncryptionKeyResult.Empty
        }
