package com.eidu.webevents.ui.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.eidu.webevents.domain.events.Learner
import com.eidu.webevents.domain.events.SchoolClass
import com.eidu.webevents.ui.Stylesheet
import dev.petuska.kmdc.list.MDCList
import dev.petuska.kmdc.list.MDCListType
import dev.petuska.kmdc.list.item.Label
import dev.petuska.kmdc.list.item.ListItem
import org.jetbrains.compose.web.dom.Img

@Composable
fun LearnerList(forClass: SchoolClass) {
    var selectedLearner: Learner? by remember { mutableStateOf(null) }

    MDCList(type = MDCListType.Avatar) {
        forClass.learners.values.map { learner ->
            ListItem(attrs = { onClick { selectedLearner = learner } }) {
                learner.photoJpegBase64?.let { encodedJpeg ->
                    Img(src = "data:image/jpg;base64,$encodedJpeg") { classes(Stylesheet.profilePhoto) }
                }
                Label(text = learner.name ?: "")
            }
        }
    }

    Dialog(
        title = "Exercise history for ${selectedLearner?.name ?: "selected learner"}",
        isVisible = selectedLearner != null,
        onClose = { selectedLearner = null }
    ) {
        selectedLearner?.let { LearnerWorkUnitList(it) }
    }
}
