package com.eidu.webevents.io.encryption

import com.benasher44.uuid.Uuid

sealed class EncryptionError(val message: String) {
    data class MissingKey(val keyId: Uuid) :
        EncryptionError("Encryption key not found when decrypting log event with keyId:$keyId")
    data class CryptoException(val exception: Exception) :
        EncryptionError("The following occurred while performing cryptographic operations: $exception")
}
