package com.eidu.webevents.io.sync

import com.eidu.webevents.io.AuthContainer
import com.eidu.webevents.io.Authenticated
import com.eidu.webevents.io.NetworkError
import com.eidu.webevents.io.Unauthenticated
import com.eidu.webevents.io.db.EventDatabase
import com.eidu.webevents.util.Outcome
import com.eidu.webevents.util.doEmit
import com.eidu.webevents.util.successValue
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow

class SyncWorker(
    private val database: EventDatabase,
    private val client: SyncClient,
    private val authContainer: AuthContainer
) {

    private val _lastSyncOutcome = MutableStateFlow<Outcome<NetworkError, DownsyncResult>?>(null)
    val lastSyncOutcome: StateFlow<Outcome<NetworkError, DownsyncResult>?> = _lastSyncOutcome

    private val _syncInProgress = MutableStateFlow(false)
    val syncInProgress: StateFlow<Boolean> = _syncInProgress

    private val _nextSyncInMs = MutableStateFlow(SYNC_INTERVAL_MS)
    val nextSyncInMs: StateFlow<Int> = _nextSyncInMs

    suspend fun syncUntilCancelled() {
        while (true) syncAndSleep()
    }

    private suspend fun syncAndSleep() {
        _nextSyncInMs.doEmit(0)
        syncIfLoggedIn()
        repeat(times = SYNC_INTERVAL_MS / SLEEP_INTERVAL_MS) { numCompletedSleeps ->
            _nextSyncInMs.doEmit(SYNC_INTERVAL_MS - numCompletedSleeps * SLEEP_INTERVAL_MS)
            delay(SLEEP_INTERVAL_MS.toLong())
        }
    }

    private suspend fun syncIfLoggedIn() =
        when (val authStatus = authContainer.authStatus) {
            Unauthenticated -> Unit
            is Authenticated -> {
                _syncInProgress.doEmit(true)
                syncUntilCompletedOrFailed(authStatus)
                _syncInProgress.doEmit(false)
            }
        }

    private suspend fun syncUntilCompletedOrFailed(authStatus: Authenticated) {
        do {
            _lastSyncOutcome.doEmit(database.downsync(client = client, authStatus = authStatus))
        } while (_lastSyncOutcome.value?.successValue is DownsyncResult.Incomplete)
    }

    companion object {
        private const val SYNC_INTERVAL_MS = 3000
        private const val SLEEP_INTERVAL_MS = 200
    }
}
