package com.eidu.webevents.io

import com.eidu.webevents.util.doEmit
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow

class AuthContainer {
    private val _authStatusFlow: MutableStateFlow<AuthenticationStatus> = MutableStateFlow(Unauthenticated)
    val authStatusFlow: StateFlow<AuthenticationStatus> = _authStatusFlow

    var authStatus: AuthenticationStatus
        get() = authStatusFlow.value
        set(value) = _authStatusFlow.doEmit(value)
}
