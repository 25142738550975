import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.eidu.webevents.Module
import com.eidu.webevents.io.Authenticated
import com.eidu.webevents.io.Unauthenticated
import com.eidu.webevents.io.account.LoginError
import com.eidu.webevents.io.account.login
import com.eidu.webevents.io.encryption.storeEncryptionKey
import com.eidu.webevents.ui.Stylesheet
import com.eidu.webevents.ui.pages.LandingPage
import com.eidu.webevents.ui.pages.LoginPage
import com.eidu.webevents.util.flatMap
import com.eidu.webevents.util.onError
import com.eidu.webevents.util.onSuccess
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.renderComposable

fun main() {
    val module = Module()
    renderComposable(rootElementId = "main") {
        Style(Stylesheet)

        val scope = rememberCoroutineScope()
        val authStatusState = module.authContainer.authStatusFlow.collectAsState()
        when (val authStatus = authStatusState.value) {
            is Authenticated ->
                LandingPage(
                    authStatus = authStatus,
                    eventDatabase = module.eventDatabase,
                    syncWorker = module.syncWorker
                )
            Unauthenticated -> {
                var error: LoginError? by remember { mutableStateOf(null) }
                LoginPage(error = error) { schoolCode ->
                    scope.launch {
                        module.authContainer
                            .login(client = module.accountClient, schoolCodeInput = schoolCode)
                            .flatMap {
                                module.keyStorage.storeEncryptionKey(client = module.encryptionClient).onError {
                                    module.authContainer.authStatus = Unauthenticated
                                }
                            }
                            .onError { error = it }
                            .onSuccess { error = null }
                    }
                }
            }
        }
        LaunchedEffect(key1 = null) { module.syncWorker.syncUntilCancelled() }
    }
}
