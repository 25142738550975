package com.eidu.webevents.io

import io.ktor.client.plugins.auth.Auth
import io.ktor.client.plugins.auth.providers.BearerTokens
import io.ktor.client.plugins.auth.providers.bearer

class HttpClientAuthConfigProvider(private val tokenType: String, private val authContainer: AuthContainer) {
    fun configure(auth: Auth) =
        auth.bearer {
            sendWithoutRequest { true }
            loadTokens { authContainer.authStatus.toBearerTokens(tokenType) }
        }
}

private fun AuthenticationStatus.toBearerTokens(tokenType: String): BearerTokens? =
    when (this) {
        is Authenticated -> BearerTokens(accessToken = "$tokenType=$authToken", refreshToken = "")
        is Unauthenticated -> null
    }
