package com.eidu.webevents.io.account.model

import kotlinx.serialization.Serializable

@Serializable
data class LoginRequest(val schoolCode: String? = null, val token: String? = null) {
    init {
        require(schoolCode != null || token != null) { "LoginRequest needs a school access code or signup token" }
    }
}
