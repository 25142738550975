package com.eidu.webevents.io.sync

import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuidFrom
import com.eidu.webevents.domain.events.Learner
import com.eidu.webevents.domain.events.LearnerWorkUnit
import com.eidu.webevents.domain.events.LogEvent
import com.eidu.webevents.domain.events.SchoolClass
import com.eidu.webevents.io.db.DataFlow
import com.eidu.webevents.io.db.DatabaseError
import com.eidu.webevents.io.db.EventDatabase
import com.eidu.webevents.io.db.map
import kotlin.time.DurationUnit
import kotlin.time.toDuration
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.jsonObject
import kotlinx.serialization.json.jsonPrimitive

fun EventDatabase.getClassesFlow(schoolId: Uuid): DataFlow<Any, Map<Uuid, SchoolClass>, DatabaseError> =
    allEvents.map { events -> getClasses(schoolId = schoolId, inEvents = events) }

fun getClasses(schoolId: Uuid, inEvents: List<LogEvent>): Map<Uuid, SchoolClass> =
    getCurrent(logId = schoolId, addEventType = "AddClass", removeEventType = "RemoveClass", inEvents = inEvents) {
            uuidFrom(it.jsonPrimitive.content)
        }
        .associateWith { classId ->
            SchoolClass(
                grade =
                    getLatest(logId = classId, type = "SetGrade", inEvents = inEvents)?.value?.jsonPrimitive?.content,
                teacherName =
                    getLatest(logId = classId, type = "SetName", inEvents = inEvents)?.value?.jsonPrimitive?.content,
                learners =
                    getCurrent(
                            logId = classId,
                            addEventType = "AddLearner",
                            removeEventType = "RemoveLearner",
                            inEvents = inEvents
                        ) {
                            uuidFrom(it.jsonPrimitive.content)
                        }
                        .associateWith { learnerId ->
                            Learner(
                                name =
                                    getLatest(logId = learnerId, type = "SetName", inEvents = inEvents)
                                        ?.value
                                        ?.jsonPrimitive
                                        ?.content,
                                photoJpegBase64 =
                                    getLatest(logId = learnerId, type = "SetPhoto", inEvents = inEvents)
                                        ?.value
                                        ?.jsonPrimitive
                                        ?.content,
                                workUnits =
                                    getEventsForLogLike(
                                            logId = learnerId,
                                            eventType = "RunWu",
                                            inEvents = inEvents,
                                        )
                                        .mapNotNull { workUnit ->
                                            val valueObj =
                                                workUnit.value.jsonObject.entries.associate { entry ->
                                                    Pair(entry.key, entry.value)
                                                }
                                            if (valueObj["result"]?.jsonPrimitive?.content == "Success")
                                                LearnerWorkUnit(
                                                    contentId = valueObj["contentId"]?.jsonPrimitive?.content ?: "",
                                                    time = workUnit.time,
                                                    score = valueObj["score"]?.jsonPrimitive?.content?.toDouble()
                                                            ?: 0.0,
                                                    duration =
                                                        (valueObj["foregroundDuration"]?.jsonPrimitive?.content ?: "0")
                                                            .toLong()
                                                            .toDuration(DurationUnit.MILLISECONDS),
                                                )
                                            else null
                                        }
                            )
                        }
            )
        }

private fun getEventsForLogLike(logId: Uuid, eventType: String, inEvents: List<LogEvent>): List<LogEvent> =
    inEvents.sortedBy { it.time }.filter { it.logId == logId && it.type == eventType }

private fun <T> getCurrent(
    logId: Uuid,
    addEventType: String,
    removeEventType: String?,
    inEvents: List<LogEvent>,
    parseValue: (JsonElement) -> T
): Set<T> =
    mutableSetOf<T>().apply {
        inEvents
            .sortedBy { it.time }
            .forEach { logEvent ->
                when {
                    logEvent.logId != logId -> Unit
                    logEvent.type == addEventType -> add(parseValue(logEvent.value))
                    logEvent.type == removeEventType -> remove(parseValue(logEvent.value))
                }
            }
    }

private fun getLatest(logId: Uuid, type: String, inEvents: List<LogEvent>): LogEvent? =
    inEvents.filter { it.logId == logId && it.type == type }.maxByOrNull { it.time }
