package com.eidu.webevents.ui.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuidFrom
import com.eidu.webevents.domain.events.SchoolClass
import com.eidu.webevents.io.AccountType
import com.eidu.webevents.io.Authenticated
import com.eidu.webevents.io.db.EventDatabase
import com.eidu.webevents.io.sync.SyncWorker
import com.eidu.webevents.io.sync.getClassesFlow
import com.eidu.webevents.ui.components.ClassDetails
import com.eidu.webevents.ui.components.EventSummary
import com.eidu.webevents.ui.components.Header
import com.eidu.webevents.ui.components.SyncInfo
import com.eidu.webevents.ui.components.Tabs
import com.eidu.webevents.util.Outcome
import com.eidu.webevents.util.withData
import dev.petuska.kmdc.list.item.Label
import dev.petuska.kmdc.select.MDCSelect
import dev.petuska.kmdc.select.MDCSelectType
import dev.petuska.kmdc.select.anchor.Anchor
import dev.petuska.kmdc.select.menu.Menu
import dev.petuska.kmdc.select.menu.SelectItem
import dev.petuska.kmdc.select.onChange
import dev.petuska.kmdc.typography.MDCBody1
import kotlinx.coroutines.flow.map
import org.jetbrains.compose.web.dom.Div

private enum class Tab(val title: String) {
    Sync("Data sync"),
    Classes("View classes")
}

@Composable
fun LandingPage(authStatus: Authenticated, eventDatabase: EventDatabase, syncWorker: SyncWorker) = Div {
    Header()
    EventSummary(eventDatabase = eventDatabase)
    when (authStatus.accountType) {
        AccountType.School -> {
            var selectedTab by remember { mutableStateOf(Tab.Sync) }
            Tabs(tabs = Tab.values(), selectedTab = selectedTab, getTitle = Tab::title) { selectedTab = it }
            when (selectedTab) {
                Tab.Sync -> SyncTab(syncWorker = syncWorker)
                Tab.Classes -> ClassesTab(schoolId = authStatus.accountId, eventDatabase = eventDatabase)
            }
        }
    }
}

@Composable
private fun SyncTab(syncWorker: SyncWorker) {
    val nextSyncMessage by
        syncWorker.nextSyncInMs
            .map { ms -> if (ms < 1000) "Next sync in <1" else "Next sync in ${ms / 1000} seconds" }
            .collectAsState(initial = "Preparing sync …")
    val syncInProgress by syncWorker.syncInProgress.collectAsState()
    val lastSyncOutcome by syncWorker.lastSyncOutcome.collectAsState()
    SyncInfo(syncInProgress = syncInProgress, nextSyncMessage = nextSyncMessage, lastSyncOutcome = lastSyncOutcome)
}

@Composable
private fun ClassesTab(schoolId: Uuid, eventDatabase: EventDatabase) = Div {
    withData(eventDatabase.getClassesFlow(schoolId = schoolId)) { classes ->
        when (classes) {
            null -> MDCBody1(text = "Loading classes …")
            is Outcome.Error -> MDCBody1(text = "Error loading classes")
            Outcome.Success(mapOf<Uuid, SchoolClass>()) -> MDCBody1(text = "No classes found")
            is Outcome.Success -> {
                var selectedClassId: Uuid? by remember { mutableStateOf(null) }
                MDCSelect(
                    type = MDCSelectType.Filled,
                    attrs = { onChange { event -> selectedClassId = uuidFrom(event.detail.value) } }
                ) {
                    Anchor(label = "Select a class")
                    Menu {
                        classes.value.forEach { (classId, schoolClass) ->
                            SelectItem(value = classId.toString(), selected = selectedClassId == classId) {
                                Label(
                                    text =
                                        "${schoolClass.grade ?: "Unknown grade"} – ${schoolClass.teacherName ?: "Teacher name unknown"}"
                                )
                            }
                        }
                    }
                }
                selectedClassId?.let { classes.value[it] }?.let { ClassDetails(it) }
            }
        }
    }
}
