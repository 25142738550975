package com.eidu.webevents.ui.components

import androidx.compose.runtime.Composable
import dev.petuska.kmdc.dialog.Action
import dev.petuska.kmdc.dialog.Actions
import dev.petuska.kmdc.dialog.Content
import dev.petuska.kmdc.dialog.Header
import dev.petuska.kmdc.dialog.MDCDialog
import dev.petuska.kmdc.dialog.MDCDialogActionsScope
import dev.petuska.kmdc.dialog.Title
import dev.petuska.kmdc.dialog.onClosed

private const val CLOSE_ACTION = "close"

@Composable
fun Dialog(
    title: String,
    isVisible: Boolean,
    onClose: () -> Unit,
    actions: @Composable MDCDialogActionsScope.() -> Unit = { Action(action = CLOSE_ACTION, text = "Close") },
    content: @Composable () -> Unit
) =
    MDCDialog(
        open = isVisible,
        scrimClickAction = CLOSE_ACTION,
        escapeKeyAction = CLOSE_ACTION,
        attrs = { onClosed { onClose() } }
    ) {
        Header { Title(title = title) }
        Content { content() }
        Actions { actions() }
    }
