package com.eidu.webevents.ui

import org.jetbrains.compose.web.css.StyleSheet
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.percent

object Stylesheet : StyleSheet() {

    val profilePhoto by style { height(100.percent) }
}
