@file:UseSerializers(UuidSerializer::class)

package com.eidu.webevents.io.encryption

import com.benasher44.uuid.Uuid
import com.eidu.webevents.serialization.UuidSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

@Serializable data class SchoolEncryptionKey(val keyId: Uuid, val jsonWebKey: JsonWebKey)
