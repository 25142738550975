package com.eidu.webevents.io

import com.benasher44.uuid.Uuid

sealed interface AuthenticationStatus

data class Authenticated(val accountType: AccountType, val accountId: Uuid, val authToken: String) :
    AuthenticationStatus

object Unauthenticated : AuthenticationStatus
