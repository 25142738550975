package com.eidu.webevents.ui.components

import androidx.compose.runtime.Composable
import com.eidu.webevents.domain.events.SchoolClass
import dev.petuska.kmdc.data.table.Body
import dev.petuska.kmdc.data.table.Cell
import dev.petuska.kmdc.data.table.Container
import dev.petuska.kmdc.data.table.MDCDataTable
import dev.petuska.kmdc.data.table.MDCDataTableHeader
import dev.petuska.kmdc.data.table.Row

@Composable
fun ClassDataTable(forClass: SchoolClass) = MDCDataTable {
    Container {
        MDCDataTableHeader {
            Cell(text = "Grade")
            Cell(text = "Teacher name")
        }
        Body {
            Row {
                Cell(text = forClass.grade ?: "Unknown")
                Cell(text = forClass.teacherName ?: "Unknown")
            }
        }
    }
}
