package com.eidu.webevents.io.account

import com.eidu.webevents.io.AccountType
import com.eidu.webevents.io.AuthContainer
import com.eidu.webevents.io.Authenticated
import com.eidu.webevents.io.account.model.LoginRequest
import com.eidu.webevents.io.account.model.LoginResult
import com.eidu.webevents.util.Outcome
import com.eidu.webevents.util.flatMap
import com.eidu.webevents.util.mapError

suspend fun AuthContainer.login(client: AccountClient, schoolCodeInput: String): Outcome<LoginError, Unit> =
    parseSchoolCode(schoolCodeInput)?.let { schoolCode ->
        client
            .logIn(LoginRequest(schoolCode = schoolCode))
            .mapError { LoginError.Client(it) }
            .flatMap { response ->
                when (response.loginResult) {
                    LoginResult.Success ->
                        when {
                            response.authToken == null -> Outcome.Error(LoginError.BadResponse(response))
                            response.schoolId == null -> Outcome.Error(LoginError.BadResponse(response))
                            else -> {
                                authStatus =
                                    Authenticated(
                                        accountType = AccountType.School,
                                        accountId = response.schoolId,
                                        authToken = response.authToken
                                    )
                                Outcome.Success(Unit)
                            }
                        }
                    LoginResult.Failure -> Outcome.Error(LoginError.BadCredentials)
                    LoginResult.UnknownError -> Outcome.Error(LoginError.UnknownError)
                }
            }
    }
        ?: Outcome.Error(LoginError.BadCredentials)
