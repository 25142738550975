package com.eidu.webevents.ui.components

import androidx.compose.runtime.Composable
import dev.petuska.kmdc.tab.Content
import dev.petuska.kmdc.tab.Label
import dev.petuska.kmdc.tab.Tab
import dev.petuska.kmdc.tab.bar.MDCTabBar
import dev.petuska.kmdc.tab.bar.onActivated
import dev.petuska.kmdc.tab.indicator.Indicator
import dev.petuska.kmdc.tab.indicator.MDCTabIndicatorTransition
import dev.petuska.kmdc.tab.indicator.Underline
import dev.petuska.kmdc.tab.scroller.Scroller

@Composable
fun <Tab> Tabs(tabs: Array<Tab>, selectedTab: Tab, getTitle: (Tab) -> String, onTabSelected: (Tab) -> Unit) =
    MDCTabBar(attrs = { onActivated { event -> onTabSelected(tabs[event.detail.index]) } }) {
        Scroller {
            tabs.map { tab ->
                val isActive = selectedTab == tab
                Tab(active = isActive) {
                    Content { Label(text = getTitle(tab)) }
                    Indicator(active = isActive, transition = MDCTabIndicatorTransition.Slide) { Underline() }
                }
            }
        }
    }
