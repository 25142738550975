package com.eidu.webevents.io

import com.eidu.webevents.util.json
import io.ktor.client.HttpClient
import io.ktor.client.engine.js.Js
import io.ktor.client.plugins.auth.Auth
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.serialization.kotlinx.json.json

class HttpClientProvider(private val clientVersion: String, private val applicationId: String) {
    fun client(authConfigProvider: HttpClientAuthConfigProvider?) =
        HttpClient(Js) {
            install(ContentNegotiation) { json(json) }
            authConfigProvider?.let { install(Auth) { it.configure(this) } }
        }
}
