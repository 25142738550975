package com.eidu.webevents.io.db

import com.benasher44.uuid.Uuid
import com.eidu.webevents.io.db.dexie.Dexie
import com.eidu.webevents.io.db.dexie.Subscription as DexieSubscription
import com.eidu.webevents.util.Outcome
import com.eidu.webevents.util.doEmit
import com.eidu.webevents.util.randomUuid
import kotlin.js.Promise
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow

class DataFlow<JsType, KotlinType, ErrorType>(
    private val toKotlin: (JsType) -> KotlinType,
    private val error: ErrorType,
    private val query: () -> Promise<JsType>
) {

    private val subscriptionIds = mutableListOf<Uuid>()

    private val observable = Dexie.liveQuery(query)
    private var dexieSubscription: DexieSubscription? = null

    private val flow = MutableStateFlow<Outcome<ErrorType, KotlinType>?>(null)

    fun <NewKotlinType, NewErrorType> map(
        transformSuccess: (KotlinType) -> NewKotlinType,
        transformError: (ErrorType) -> NewErrorType
    ): DataFlow<JsType, NewKotlinType, NewErrorType> =
        DataFlow(
            toKotlin = { value -> transformSuccess(toKotlin(value)) },
            error = transformError(error),
            query = query
        )

    fun subscribe(): Subscription<Outcome<ErrorType, KotlinType>> {
        val subscriptionId = randomUuid()
        subscriptionIds += subscriptionId
        if (dexieSubscription == null)
            dexieSubscription =
                observable.subscribe(
                    onNext = { value -> emit(Outcome.Success(toKotlin(value))) },
                    onError = { emit(Outcome.Error(error)) }
                )
        return Subscription(flow) { unsubscribe(subscriptionId) }
    }

    private fun unsubscribe(subscriptionId: Uuid) {
        subscriptionIds -= subscriptionId
        if (subscriptionIds.isEmpty()) {
            dexieSubscription?.unsubscribe()
            dexieSubscription = null
            emit(null)
        }
    }

    private fun emit(value: Outcome<ErrorType, KotlinType>?) = flow.doEmit(value)

    class Subscription<T>(val flow: StateFlow<T?>, val unsubscribe: () -> Unit)
}

fun <JsType, KotlinType, NewKotlinType, ErrorType> DataFlow<JsType, KotlinType, ErrorType>.map(
    transformSuccess: (KotlinType) -> NewKotlinType
): DataFlow<JsType, NewKotlinType, ErrorType> = map(transformSuccess = transformSuccess, transformError = { it })
