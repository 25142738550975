package com.eidu.webevents.ui.components

import androidx.compose.runtime.Composable
import com.eidu.webevents.io.db.EventDatabase
import com.eidu.webevents.util.Outcome
import com.eidu.webevents.util.withData
import dev.petuska.kmdc.typography.MDCBody1

@Composable
fun EventSummary(eventDatabase: EventDatabase) =
    withData(eventDatabase.allEvents) { allEvents ->
        MDCBody1(
            text =
                when (allEvents) {
                    is Outcome.Success -> "${allEvents.value.size} events in the database"
                    is Outcome.Error -> "Failed to query events for summary"
                    null -> "Loading events summary …"
                }
        )
    }
