package com.eidu.webevents.io.sync

import com.benasher44.uuid.Uuid
import com.eidu.webevents.serialization.UuidSerializer
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.Serializer
import kotlinx.serialization.builtins.MapSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

@Serializable
data class EventSelector(val maxSequenceNumberBySourceByLog: Map<Uuid, Map<Uuid, Long>>) {

    fun isEmpty() = maxSequenceNumberBySourceByLog.isEmpty()

    @OptIn(ExperimentalSerializationApi::class)
    @Serializer(forClass = EventSelector::class)
    companion object : KSerializer<EventSelector> {
        private val valueSerializer = MapSerializer(UuidSerializer, MapSerializer(UuidSerializer, Long.serializer()))

        override val descriptor: SerialDescriptor = valueSerializer.descriptor

        override fun serialize(encoder: Encoder, value: EventSelector) =
            encoder.encodeSerializableValue(valueSerializer, value.maxSequenceNumberBySourceByLog)

        override fun deserialize(decoder: Decoder): EventSelector =
            EventSelector(decoder.decodeSerializableValue(valueSerializer))
    }
}
