package com.eidu.webevents.ui.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.eidu.webevents.io.account.LoginError
import com.eidu.webevents.io.account.parseSchoolCode
import com.eidu.webevents.ui.components.Header
import dev.petuska.kmdc.button.Label
import dev.petuska.kmdc.button.MDCButton
import dev.petuska.kmdc.button.MDCButtonType
import dev.petuska.kmdc.textfield.MDCTextField
import dev.petuska.kmdc.textfield.MDCTextFieldType
import dev.petuska.kmdc.typography.MDCBody2
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.dom.Div

@Composable
fun LoginPage(error: LoginError?, login: (schoolCode: String) -> Unit) = Div {
    Header()
    LoginWidget(error = error, login = login)
}

@Composable
private fun LoginWidget(error: LoginError?, login: (schoolCode: String) -> Unit) = Div {
    var schoolCode by remember { mutableStateOf("") }
    MDCTextField(value = schoolCode, type = MDCTextFieldType.Filled, label = "Enter school code") {
        onInput { event -> schoolCode = event.value }
    }
    MDCButton(
        type = MDCButtonType.Raised,
        attrs = {
            onClick { login(schoolCode) }
            if (parseSchoolCode(schoolCode) == null) disabled()
        },
        content = { Label(text = "Sign in") }
    )
    error?.let { MDCBody2(text = it.message) }
}
