package com.eidu.webevents.io.db

import com.eidu.webevents.domain.events.LogEvent
import com.eidu.webevents.io.db.dexie.Dexie
import com.eidu.webevents.io.encryption.KeyStorage
import com.eidu.webevents.util.json
import com.eidu.webevents.util.successValue
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine
import kotlin.js.Promise
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString

class EventDatabase(private val encryptionKeyStorage: KeyStorage) {
    private val dexie =
        Dexie(dbName = DATABASE_NAME).apply {
            version(VERSION).stores(kotlin.js.json(EVENTS_COLLECTION_NAME to EVENTS_COLLECTION_SCHEMA))
        }

    private val eventsCollection = dexie.asDynamic().events

    val allEvents: DataFlow<Any, List<LogEvent>, DatabaseError> =
        DataFlow<Any, List<LogEvent>, DatabaseError>(toKotlin = { value -> value.asEvents() }, error = UnknownError) {
                eventsCollection.toArray() as Promise<Any>
            }
            .map { events -> events.decrypted() }

    suspend fun getAll(): List<LogEvent> = suspendCoroutine { continuation ->
        eventsCollection.toArray { value -> continuation.resume((value as Any).asEvents().decrypted()) }
        Unit
    }

    fun putEvents(events: List<LogEvent>) {
        eventsCollection.bulkPut(JSON.parse(json.encodeToString(events)))
    }

    private fun List<LogEvent>.decrypted(): List<LogEvent> = mapNotNull { event ->
        encryptionKeyStorage.decrypt(logEvent = event).successValue
    }

    companion object {
        private const val DATABASE_NAME = "events"
        private const val VERSION = 1

        private const val EVENTS_COLLECTION_NAME = "events"
        private const val EVENTS_COLLECTION_SCHEMA = "++id, sourceId, sequenceNumber, logId, time, type"
    }
}

private fun Any.asEvents(): List<LogEvent> = json.decodeFromString(JSON.stringify(this))
