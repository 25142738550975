package com.eidu.webevents.util

import kotlin.coroutines.cancellation.CancellationException

sealed class Outcome<out E, out S> {
    abstract val isSuccess: Boolean
    abstract val isError: Boolean

    data class Success<S>(val value: S) : Outcome<Nothing, S>() {
        override val isSuccess = true
        override val isError = false
    }

    data class Error<E>(val value: E) : Outcome<E, Nothing>() {
        override val isSuccess = false
        override val isError = true
    }
}

val <E, S> Outcome<E, S>.successValue: S?
    get() =
        when (this) {
            is Outcome.Success -> value
            is Outcome.Error -> null
        }

inline fun <E, S, S2> Outcome<E, S>.map(f: (S) -> S2): Outcome<E, S2> =
    when (this) {
        is Outcome.Success -> Outcome.Success(f(value))
        is Outcome.Error -> this
    }

inline fun <E, E2, S> Outcome<E, S>.mapError(f: (E) -> E2): Outcome<E2, S> =
    when (this) {
        is Outcome.Success -> this
        is Outcome.Error -> Outcome.Error(f(value))
    }

inline fun <E, S> Outcome<E, S>.onSuccess(f: (S) -> Unit): Outcome<E, S> = also {
    when (this) {
        is Outcome.Success -> f(value)
        is Outcome.Error -> Unit
    }
}

inline fun <E, S> Outcome<E, S>.onError(f: (E) -> Unit): Outcome<E, S> = also {
    when (this) {
        is Outcome.Success -> Unit
        is Outcome.Error -> f(value)
    }
}

inline fun <E, E2, S> Outcome<E, S>.recover(f: (E) -> Outcome<E2, S>): Outcome<E2, S> =
    when (this) {
        is Outcome.Success -> this
        is Outcome.Error -> f(value)
    }

inline fun <A, B, C> Outcome<A, B>.flatMap(f: (B) -> Outcome<A, C>): Outcome<A, C> =
    when (this) {
        is Outcome.Success -> f(value)
        is Outcome.Error -> this
    }

fun <A, B> Outcome<A, B>.swap(): Outcome<B, A> =
    when (this) {
        is Outcome.Success -> Outcome.Error(value)
        is Outcome.Error -> Outcome.Success(value)
    }

fun <T> Outcome<T, T>.merge() =
    when (this) {
        is Outcome.Success -> value
        is Outcome.Error -> value
    }

fun <S, E> S?.toOutcome(error: () -> E) = this?.let { Outcome.Success(it) } ?: Outcome.Error(error())

inline fun <E, S> runAndCatch(wrapException: (exception: Exception) -> E, block: () -> S): Outcome<E, S> =
    try {
        Outcome.Success(block())
    } catch (e: Exception) {
        if (e is CancellationException) throw e else Outcome.Error(wrapException(e))
    }
