package com.eidu.webevents.io.account

import com.eidu.webevents.io.ApiUrlProvider
import com.eidu.webevents.io.ClientError
import com.eidu.webevents.io.account.model.LoginRequest
import com.eidu.webevents.io.account.model.LoginResponse
import com.eidu.webevents.util.Outcome
import com.eidu.webevents.util.runAndCatch
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.plugins.expectSuccess
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.http.ContentType
import io.ktor.http.contentType

class AccountClient(private val client: HttpClient, private val apiUrlProvider: ApiUrlProvider) {
    private fun loginUrl() = "${apiUrlProvider.getBaseUrl()}school-login"

    suspend fun logIn(body: LoginRequest): Outcome<ClientError, LoginResponse> =
        runAndCatch(::ClientError) {
            client
                .post(loginUrl()) {
                    expectSuccess = true
                    contentType(ContentType.Application.Json)
                    setBody(body)
                }
                .body()
        }
}
