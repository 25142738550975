package com.eidu.webevents.io.encryption

import com.benasher44.uuid.Uuid
import com.eidu.webevents.domain.events.LogEvent
import com.eidu.webevents.util.Outcome
import com.eidu.webevents.util.flatMap
import com.eidu.webevents.util.map
import com.eidu.webevents.util.toOutcome

class KeyStorage {
    private val keyStorage: MutableMap<Uuid, SchoolEncryptionKey> = mutableMapOf()

    private fun loadOrNull(keyId: Uuid): SchoolEncryptionKey? = keyStorage[keyId]

    private fun keyIdExists(keyId: Uuid): Boolean = keyId in keyStorage

    private fun loadEncryptionKey(keyId: Uuid): Outcome<EncryptionError, SchoolEncryptionKey> =
        loadOrNull(keyId).toOutcome { EncryptionError.MissingKey(keyId) }

    fun storeIfNew(schoolEncryptionKey: SchoolEncryptionKey) {
        schoolEncryptionKey.keyId.takeUnless { keyIdExists(keyId = it) }?.let { keyStorage[it] = schoolEncryptionKey }
    }

    fun decrypt(logEvent: LogEvent): Outcome<EncryptionError, LogEvent> =
        logEvent.encryptionKeyId?.let { keyId ->
            loadEncryptionKey(keyId)
                .flatMap { key -> Decryptor().decryptJsonElement(key, logEvent.value) }
                .map { value -> logEvent.withValue(value) }
        }
            ?: Outcome.Success(logEvent)
}
