@file:UseSerializers(UuidSerializer::class, InstantSerializer::class)

package com.eidu.webevents.domain.events

import com.benasher44.uuid.Uuid
import com.eidu.webevents.serialization.InstantSerializer
import com.eidu.webevents.serialization.UuidSerializer
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

@Serializable
open class Event<out T>(
    val sourceId: Uuid,
    val sequenceNumber: Long,
    val logId: Uuid,
    val time: Instant,
    val appVersion: Int,
    val type: String,
    val value: T,
    val minTime: Instant? = null,
    val maxTime: Instant? = null,
    val encryptionKeyId: Uuid? = null
) : Comparable<Event<*>> {

    /*
     * Note: Considers only the primary key fields (source and sequenceNumber) as these will be sufficient for
     * most purposes.
     */
    override fun hashCode(): Int = sourceId.hashCode() xor sequenceNumber.hashCode()

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class.js != other::class.js) return false

        other as Event<*>

        if (sourceId != other.sourceId) return false
        if (sequenceNumber != other.sequenceNumber) return false
        if (logId != other.logId) return false
        if (time != other.time) return false
        if (appVersion != other.appVersion) return false
        if (type != other.type) return false
        if (value != other.value) return false
        if (minTime != other.minTime) return false
        if (maxTime != other.maxTime) return false
        if (encryptionKeyId != other.encryptionKeyId) return false

        return true
    }

    open fun <U> withValue(newValue: U): Event<U> =
        Event(sourceId, sequenceNumber, logId, time, appVersion, type, newValue, minTime, maxTime, encryptionKeyId)

    override fun compareTo(other: Event<*>): Int = compareValuesBy(this, other, { it.time }, { it.sequenceNumber })
}
