package com.eidu.webevents.ui.components

import androidx.compose.runtime.Composable
import com.eidu.webevents.domain.events.Learner
import dev.petuska.kmdc.list.MDCList
import dev.petuska.kmdc.list.MDCListSize
import dev.petuska.kmdc.list.item.Label
import dev.petuska.kmdc.list.item.ListItem
import dev.petuska.kmdc.list.item.Primary
import dev.petuska.kmdc.list.item.Secondary

@Composable
fun LearnerWorkUnitList(forLearner: Learner) =
    MDCList(dense = true, size = MDCListSize.TwoLine) {
        forLearner.workUnits?.let { workUnits ->
            workUnits.forEach { workUnit ->
                ListItem {
                    Label {
                        Primary("Exercise content: ${workUnit.contentId}")
                        Secondary(
                            "Time of exercise: ${workUnit.time}, " +
                                "Time taken: ${workUnit.duration}, " +
                                "Learner score: ${workUnit.score * 100}%"
                        )
                    }
                }
            }
        }
    }
