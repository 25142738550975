package com.eidu.webevents.io.account

import com.eidu.webevents.io.ClientError
import com.eidu.webevents.io.account.model.LoginResponse

sealed class LoginError(val message: String) {
    data class Client(val cause: ClientError) :
        LoginError("Could not connect to EIDU. Please check your network connection.")
    data class BadResponse(val response: LoginResponse) : LoginError("Sorry, something went wrong …")
    object BadCredentials : LoginError("Invalid school code")
    object UnknownError : LoginError("Unknown error")
}
