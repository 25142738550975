package com.eidu.webevents.io.encryption

import com.eidu.webevents.io.encryption.EncryptionError.CryptoException
import com.eidu.webevents.util.Outcome
import com.eidu.webevents.util.json
import com.eidu.webevents.util.runAndCatch
import com.soywiz.krypto.AES
import com.soywiz.krypto.Padding
import com.soywiz.krypto.encoding.fromBase64
import io.ktor.utils.io.core.String
import kotlinx.serialization.Serializable
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.decodeFromJsonElement

@Serializable private data class EncryptedMessage(val cipherText: String, val iv: String)

class Decryptor {
    fun decryptJsonElement(key: SchoolEncryptionKey, value: JsonElement): Outcome<EncryptionError, JsonElement> =
        runAndCatch(::CryptoException) {
            val encryptedMessage: EncryptedMessage = json.decodeFromJsonElement(value)
            val decryptedStringAsBytes = decryptMessageWithAES(key, encryptedMessage)
            json.decodeFromString(String(decryptedStringAsBytes))
        }

    private fun decryptMessageWithAES(key: SchoolEncryptionKey, encryptedMessage: EncryptedMessage): ByteArray =
        String(
                AES.decryptAesCbc(
                    data = encryptedMessage.cipherText.fromBase64(),
                    key = key.jsonWebKey.key.fromBase64(url = true),
                    iv = encryptedMessage.iv.fromBase64(),
                    padding = Padding.PKCS7Padding
                )
            )
            .fromBase64()
}
