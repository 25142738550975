package com.eidu.webevents

import com.eidu.webevents.io.ApiUrlProvider
import com.eidu.webevents.io.AuthContainer
import com.eidu.webevents.io.BackendStageProvider
import com.eidu.webevents.io.HttpClientAuthConfigProvider
import com.eidu.webevents.io.HttpClientProvider
import com.eidu.webevents.io.account.AccountClient
import com.eidu.webevents.io.db.EventDatabase
import com.eidu.webevents.io.encryption.EncryptionClient
import com.eidu.webevents.io.encryption.KeyStorage
import com.eidu.webevents.io.sync.SyncClient
import com.eidu.webevents.io.sync.SyncWorker

class Module {
    val eventDatabase by lazy { EventDatabase(encryptionKeyStorage = keyStorage) }

    val authContainer by lazy { AuthContainer() }

    val keyStorage: KeyStorage by lazy { KeyStorage() }

    private val authenticatedHttpClient by lazy {
        HttpClientProvider(clientVersion = APP_VERSION, applicationId = APP_ID)
            .client(
                authConfigProvider = HttpClientAuthConfigProvider(tokenType = "schoolId", authContainer = authContainer)
            )
    }

    private val unauthenticatedHttpClient by lazy {
        HttpClientProvider(clientVersion = APP_VERSION, applicationId = APP_ID).client(authConfigProvider = null)
    }

    private val backendStageProvider by lazy { BackendStageProvider() }

    private val apiUrlProvider by lazy { ApiUrlProvider(backendStageProvider) }

    val accountClient by lazy { AccountClient(client = unauthenticatedHttpClient, apiUrlProvider = apiUrlProvider) }

    val encryptionClient by lazy { EncryptionClient(client = authenticatedHttpClient, apiUrlProvider = apiUrlProvider) }

    val syncClient by lazy { SyncClient(client = authenticatedHttpClient, apiUrlProvider = apiUrlProvider) }

    val syncWorker by lazy { SyncWorker(database = eventDatabase, client = syncClient, authContainer = authContainer) }

    companion object {
        private const val APP_ID = "web-events"
        private const val APP_VERSION = "1.0"
    }
}
