package com.eidu.webevents.io.sync

import com.benasher44.uuid.Uuid
import com.eidu.webevents.domain.events.LogEvent
import com.eidu.webevents.io.AccountType
import com.eidu.webevents.io.Authenticated
import com.eidu.webevents.io.NetworkError
import com.eidu.webevents.io.Version
import com.eidu.webevents.io.db.EventDatabase
import com.eidu.webevents.util.Outcome
import com.eidu.webevents.util.map
import com.eidu.webevents.util.onSuccess

private const val TARGET_NUM_RESPONSE_BYTES = 1000_000

suspend fun EventDatabase.downsync(
    client: SyncClient,
    authStatus: Authenticated
): Outcome<NetworkError, DownsyncResult> {
    val maxSequenceNumberBySourceByLog = getMaxSequenceNumberBySourceByLog(authStatus = authStatus, inEvents = getAll())
    return client
        .downsync(
            DownsyncQuery(
                query = EventSelector(maxSequenceNumberBySourceByLog = maxSequenceNumberBySourceByLog),
                appVersion = Version,
                targetResponseSize = TARGET_NUM_RESPONSE_BYTES
            )
        )
        .onSuccess { putEvents(it.events) }
        .map { response ->
            when {
                response.events.isEmpty() -> DownsyncResult.Empty
                response.truncated ||
                    getMaxSequenceNumberBySourceByLog(authStatus = authStatus, inEvents = getAll()).keys !=
                        maxSequenceNumberBySourceByLog.keys -> DownsyncResult.Incomplete(response.events)
                else -> DownsyncResult.Complete(response.events)
            }
        }
}

private fun getMaxSequenceNumberBySourceByLog(
    authStatus: Authenticated,
    inEvents: List<LogEvent>
): Map<Uuid, Map<Uuid, Long>> =
    mutableMapOf<Uuid, Map<Uuid, Long>>().apply {
        inEvents.forEach { logEvent ->
            set(
                logEvent.logId,
                if (get(logEvent.logId).orEmpty()[logEvent.sourceId]?.let { it > logEvent.sequenceNumber } != true)
                    get(logEvent.logId).orEmpty() + (logEvent.sourceId to logEvent.sequenceNumber)
                else get(logEvent.logId).orEmpty()
            )
        }
        getOrPut(authStatus.accountId, ::emptyMap)
        when (authStatus.accountType) {
            AccountType.School ->
                getClassAndLearnerLogIds(schoolId = authStatus.accountId, inEvents = inEvents).forEach { logId ->
                    getOrPut(logId, ::emptyMap)
                }
        }
    }

private fun getClassAndLearnerLogIds(schoolId: Uuid, inEvents: List<LogEvent>): Set<Uuid> =
    getClasses(schoolId = schoolId, inEvents = inEvents)
        .flatMap { (classId, schoolClass) -> listOf(classId) + schoolClass.learners.keys }
        .toSet()
