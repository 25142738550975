package com.eidu.webevents.ui.components

import androidx.compose.runtime.Composable
import com.eidu.webevents.io.NetworkError
import com.eidu.webevents.io.sync.DownsyncResult
import com.eidu.webevents.util.Outcome
import dev.petuska.kmdc.typography.MDCBody1
import org.jetbrains.compose.web.dom.Div

@Composable
fun SyncInfo(
    syncInProgress: Boolean,
    nextSyncMessage: String,
    lastSyncOutcome: Outcome<NetworkError, DownsyncResult>?
) = Div {
    MDCBody1(text = if (syncInProgress) "Syncing now!" else nextSyncMessage)
    MDCBody1(
        text =
            when (lastSyncOutcome) {
                is Outcome.Error -> "Could not connect to EIDU – please check your network connection"
                is Outcome.Success ->
                    when (val syncResult = lastSyncOutcome.value) {
                        is DownsyncResult.Complete -> "Received ${syncResult.events.size} events. All caught up!"
                        is DownsyncResult.Incomplete ->
                            "Received ${syncResult.events.size} events. There are more on the server"
                        DownsyncResult.Empty -> "Already up-to-date"
                    }
                null -> if (syncInProgress) "Connecting to EIDU …" else "Sync will start soon"
            }
    )
}
