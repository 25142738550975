package com.eidu.webevents.io.sync

import com.eidu.webevents.io.ApiUrlProvider
import com.eidu.webevents.io.ClientError
import com.eidu.webevents.io.NetworkError
import com.eidu.webevents.util.Outcome
import com.eidu.webevents.util.runAndCatch
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.plugins.expectSuccess
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.http.ContentType
import io.ktor.http.contentType

class SyncClient(private val client: HttpClient, private val apiUrlProvider: ApiUrlProvider) {
    suspend fun downsync(query: DownsyncQuery): Outcome<NetworkError, DownsyncResponse> =
        runAndCatch(::ClientError) {
            client
                .post(apiUrlProvider.getBaseUrl() + DOWNSYNC_REQUEST_PATH) {
                    expectSuccess = true
                    contentType(ContentType.Application.Json)
                    setBody(query)
                }
                .body()
        }

    companion object {
        private const val DOWNSYNC_REQUEST_PATH = "get-events"
    }
}
