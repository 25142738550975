package com.eidu.webevents.io.encryption

import com.eidu.webevents.io.ApiUrlProvider
import com.eidu.webevents.io.ClientError
import com.eidu.webevents.util.Outcome
import com.eidu.webevents.util.runAndCatch
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.plugins.expectSuccess
import io.ktor.client.request.get

class EncryptionClient(private val client: HttpClient, private val apiUrlProvider: ApiUrlProvider) {
    private fun encryptionKeyUrl() = "${apiUrlProvider.getBaseUrl()}get-encryption-key"

    suspend fun getEncryptionKey(): Outcome<ClientError, EncryptionKeyResponse> =
        runAndCatch(::ClientError) { client.get(encryptionKeyUrl()) { expectSuccess = true }.body() }
}
