package com.eidu.webevents.ui.components

import androidx.compose.runtime.Composable
import com.eidu.webevents.domain.events.SchoolClass
import dev.petuska.kmdc.typography.MDCSubtitle1
import org.jetbrains.compose.web.dom.Div

@Composable
fun ClassDetails(schoolClass: SchoolClass) = Div {
    ClassDataTable(forClass = schoolClass)
    MDCSubtitle1(text = "${schoolClass.learners.size} Learner(s)")
    LearnerList(forClass = schoolClass)
}
