@file:UseSerializers(UuidSerializer::class)

package com.eidu.webevents.io.encryption

import com.eidu.webevents.serialization.UuidSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

@Serializable
data class JsonWebKey(
    @SerialName("alg") val algorithm: String,
    @SerialName("ext") val extractable: Boolean,
    @SerialName("k") val key: String,
    @SerialName("key_ops") val keyOperations: List<String>,
    @SerialName("kty") val keyType: String
)
