@file:UseSerializers(VersionSerializer::class)

package com.eidu.webevents.io.sync

import com.eidu.webevents.io.Version
import com.eidu.webevents.io.VersionSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

@Serializable
data class DownsyncQuery(val query: EventSelector, val appVersion: Version, val targetResponseSize: Int? = null) {
    fun isEmpty() = query.isEmpty()
}
